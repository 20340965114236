import { FC, useEffect, useState } from 'react'
import { Button } from '@magal/components'
import {
  SectionProductConversionAreaProps,
  ShopifyImageMediaPayload,
  ShopifyPrice,
} from '@magal/models'
import { styled } from '@magal/styles'
import {
  PricePrimitive,
  ProductPrice,
  ProductSwatches,
  ProductVariantPrice,
  shopifyImageLoader,
} from '@magal/components'
import { pushProductToRecentlyViewed } from '@magal/services/shopify-service'
import { useComputedProductData } from './useComputedProductData'
import { useTranslation } from 'react-i18next'
import { AddToCartButton } from '@magal/cart'
import { ProductGallery } from './components/ProductGallery/ProductGallery'
import { SizeGuide } from './components/SizeGuide'
import { AccordionTransparentPricing } from './components/AccordionTransparentPricing/AccordionTransparentPricing'
import {
  AccordionProductDetails,
  ProductMaterialProps,
} from './components/AccordionProductDetails/AccordionProductDetails'
import { AccordionWithContent } from './components/AccordionWithContent/AccordionWithContent'
import { useProductsSettings } from '@magal/product'
import { KlarnaWidget } from './components/KlarnaWidget/KlarnaWidget'
import {
  AttentiveProductView,
  GTMViewItem,
  TriplePageLoad,
} from '@magal/services/gtm-service'
import { ProductWizard } from './components/ProductWizard'
import { ProductTiles } from './components/ProductTiles'
import { ProductOptions } from './components/ProductOptions'
import { PersonalizeModal } from './components/PersonalizeModal'
import { ProductJsonLd } from 'next-seo'
import { useRouter } from 'next/router'
import { ProductReviews } from './components/ProductReviews'
import { PortalProductCart } from './components/PortalProductCart'
import { ContainerElements } from './components/ContainerElements'
import { DiscountPercentage, formatDiscount } from '@magal/utils'
import { Upsells } from './components/Upsells/Upsells'
import { Reassurance } from './components/Reassurance'

export type ProductDetailPageType =
  | 'SINGLE_VARIANT'
  | 'VARIANT_DERIVED_FROM_OPTIONS'
  | 'ADVANCED_TEMPLATE'
  | undefined

export type OnProductOptionChange = (optionId: string, valueId?: string) => void
export type ProductAttrs = { key: string; value: string }[]
const Root = styled('div', {
  color: '$green_09',
  position: 'relative',
  display: 'grid',
  alignItems: 'start',
  '@lg': {
    gridTemplateColumns: '3fr 2fr',
  },
  '@xl': {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
})
const GalleryArea = styled('div', {
  display: 'grid',
  minHeight: '$192',
  '@xl': {
    gridColumn: 'span 2',
  },
})
const Aside = styled('div', {
  display: 'grid',
  gridGap: '$16',
  padding: '$16 $containerMarginMobile',
  '@lg': {
    padding: '$40 $containerMarginDesktop',
    minWidth: '$416',
  },
})

const WrapperUpsells = styled('div', {
  background: 'rgb(249, 246, 242)',
  bottom: 0,
  display: 'block',
  height: '100%',
  right: 0,
  padding: '15px',
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 9999,
  '@lg': {
    display: 'none',
    width: '579px',
  },
  variants: {
    closeUpsells: {
      false: {
        display: 'none',
      },
      true: {
        display: 'flex',
        alignItems: 'center',
        '@lg': {
          // display: 'none',
        },
      },
    },
  },
})

const MetaArea = styled('div', {
  display: 'grid',
  gridGap: '$12',
})

const BuyArea = styled('div', {
  display: 'grid',
  variants: {
    hideOnMobile: {
      true: {
        display: 'none',
        '@lg': { display: 'grid' },
      },
    },
  },
})

const WizardWrap = styled('div', {
  display: 'none',
  '@lg': { display: 'grid' },
})

const WizardContainer = styled('div', {
  padding: '0 $8',
  border: '3px solid transparent',
  transition: 'border 400ms',
  '@lg': { padding: 0 },
  variants: {
    hasError: {
      true: {
        borderColor: 'transparent', //$red400
        borderRadius: '$r2',
      },
    },
  },
})

const WizardError = styled('p', {
  color: '$red400',
  textAlign: 'center',
  fontFamily: 'FormaDJRMicro',
  fontWeight: 500,
  paddingTop: '$12',
})

const OptionsArea = styled('div', {
  display: 'grid',
  gridGap: '$16',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
})

const DetailsArea = styled('div', {
  display: 'grid',
  margin: '0 -$containerMarginMobile',
  '@lg': {
    margin: 0,
  },
  hr: {
    border: 'none',
    margin: 0,
    borderTop: '1px solid currentColor',
    width: '100%',
  },
})

const ProductColor = styled('div', {
  projectFont: 'caps07',
})

const ProductTitle = styled('h1', {
  projectFont: 'caps02',
})

const PortalProductTitle = styled('h4', {
  projectFont: 'caps10',
})
const PortalPricePrimitive = styled('span', {
  projectFont: 'caps11',
})

const ContainerButton = styled('div', {
  '@lg': {
    display: 'none',
  },
})

const PriceWrap = styled('div', {
  projectFont: 'heading04Medium',
  padding: '$12 0',
  '@lg': {
    padding: '$12 0 $32',
  },
})

export const ProductOptionLabel = styled('div', {
  whiteSpace: 'nowrap',
  color: '$green_09',
  projectFont: 'body01',
})

export const ProductMetaData = styled('div', {
  display: 'flex',
})

export const ProductMetaDataFull = styled('div', {
  width: '100%',
})

export const ReviewWrapper = styled('div', {
  marginBottom: '18px',
})

export const WizardComponent = styled('div', {})

const Col = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  '&:not(&:last-child)': {
    display: 'none',
    '@lg': {
      flexGrow: '1',
      display: 'flex',
    },
  },
  '&:last-child': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '> button': {
      display: 'none',
    },
    '> div': {
      width: '100%',
    },
    '@lg': {
      maxWidth: '346px',
      width: '100%',
      '> button': { display: 'block' },
    },
  },
})

export const SectionProductConversionArea: FC<
  SectionProductConversionAreaProps
> = ({
  productId,
  shopifyData,
  enableProductCare,
  productCare,
  enableShippingReturns,
  shippingReturns,
  productMaterials,
  productTiles,
  sizeGuide,
  enableTransparentPricing,
  productDescriptionLabel,
  productCareLabel,
  shippingReturnsLabel,
  transparentPricingLabel,
  transparentPricingMultiplier = 2,
  advancedTemplate,
  enableMaterials,
  reassurance,
}) => {
  const [personalizeModalOpen, setPersonalizeModalOpen] = useState(false)
  const [wizardDone, setWizardDone] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const openPersonalizeModal = () => setPersonalizeModalOpen(true)
  const closePersonalizeModal = () => setPersonalizeModalOpen(false)
  const [isHidden, setIsHidden] = useState(false)
  const [discount, setDiscount] = useState<DiscountPercentage>()
  const [productAttrs, setProductAttrs] = useState<ProductAttrs>([])
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [extraPrice, setExtraPrice] = useState<number>(0)
  const { upsells } = useProductsSettings()
  const { asPath } = useRouter()
  const { t } = useTranslation('sectionProductConversionArea')
  const fullUrl = `${process.env.BASE_URL}${asPath}`

  const [showUpsellsMobile, setShowUpsellsMobile] = useState(false)

  const productData = shopifyData?.productExtended
    ? shopifyData?.productExtended[productId]
    : undefined

  if (!productData || !shopifyData) {
    throw new Error(
      `[SectionProductConversionArea] No enough data to render section. `,
    )
  }

  const productVariants = productData.variants

  const { updateProductColorsMap, colorsConfig, getProductColors } =
    useProductsSettings()

  const {
    currentMainVariant,
    currentSoftMainVariant,
    computedProductOptions,
    onProductOptionChange,
    pageType,
    attributes,
    currentAdditionalVariants,
    currentPrice,
    currentCompareAtPrice,
    isConfiguratorDirty,
  } = useComputedProductData(productData, shopifyData, advancedTemplate)

  const priceAdjust = (current: any, extra: number): any => {
    return {
      amount: `${parseFloat(current?.amount) + extra}`,
      currencyCode: current?.currencyCode,
    }
  }

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsMobile(true)
    }

    pushProductToRecentlyViewed(productData.id)
    updateProductColorsMap([productData.title])
  }, [])

  useEffect(() => {
    if (productId) {
      GTMViewItem(
        { ...productData, title: productData.fullTitle },
        productData.variants[0],
      )
    }
  }, [productId])

  useEffect(() => {
    AttentiveProductView(
      { ...productData, title: productData.fullTitle },
      productData.variants[0],
    )

    TriplePageLoad({
      product: {
        id: productData.id,
        name: productData.title,
        price: productData.variants[0].priceV2.amount,
        variant: productData.variants[0].id,
      },
    })
  }, [productData])

  useEffect(() => {
    setTimeout(() => {
      if (window.discountPercentage.enableDiscount) {
        setDiscount(window.discountPercentage)
      }
    }, 100)
  }, [])

  const handleProductChange = (
    optionId: string,
    valueId?: string,
    attrs?: ProductAttrs,
  ): void => {
    setHasErrors(false)
    if (attrs) {
      setProductAttrs(attrs)
    }

    return onProductOptionChange(optionId, valueId)
  }

  // COMPUTED VARIABLES
  const handleIntersection = (intersect: boolean) => {
    setIsHidden(!intersect)
  }

  const productColors = getProductColors(productData.title)

  const currentProductColor =
    colorsConfig && productData.color
      ? colorsConfig[productData.color]
      : undefined

  const selectedVariantImage =
    currentMainVariant?.image ?? currentSoftMainVariant?.image

  const computedProductImages = (() => {
    if (pageType === 'SINGLE_VARIANT') {
      return productData.images
    }

    const featuredImage = productData.images[0]

    const productImagesWithoutVariantImages = productData.media.filter(
      (productMedia) => {
        const productVariantsImagesSrcs = productVariants.map(
          (variant) => variant.image?.image.src,
        )

        if (productMedia.type === 'shopify-image') {
          return (
            !productVariantsImagesSrcs.includes(productMedia.image.src) &&
            productMedia.image.src !== featuredImage.image.src
          )
        }
        return true
      },
    )

    if (selectedVariantImage) {
      return [
        featuredImage,
        selectedVariantImage,
        ...productImagesWithoutVariantImages,
      ]
    }

    return [featuredImage, ...productImagesWithoutVariantImages]
  })()

  const allOptionsSelected = (() => {
    if (pageType === 'SINGLE_VARIANT') return true
    return pageType === 'ADVANCED_TEMPLATE'
      ? wizardDone
      : computedProductOptions.every((o) => !!o.selectedValue)
  })()

  const testProductsPopup: string[] = []
  const canFakeCart = testProductsPopup.includes(productData.handle)
  const handleFakeCartClick = () => {
    if (window.innerWidth >= 760) {
      //call the desktop creative
      window.__attentive.trigger(null, null, null, 972768)
    } else {
      //call the mobile creative
      window.__attentive.trigger(null, null, null, 972753)
    }
  }

  // ELEMS
  const galleryElem = (hideFirstImage = false) => (
    <ProductGallery
      handle={productData.handle}
      images={
        hideFirstImage
          ? computedProductImages.slice(1, computedProductImages.length)
          : computedProductImages
      }
    />
  )

  const handleShowUpsells = (off = false) => {
    setShowUpsellsMobile(off)
  }

  const allAttrs = () => [...attributes, ...productAttrs]

  const addToCartDoneElem = (
    <AddToCartButton
      onError={setHasErrors}
      mainVariant={currentMainVariant}
      attributes={allAttrs()}
      readyToAdd={allOptionsSelected}
      additionalVariants={currentAdditionalVariants}
      currentPrice={priceAdjust(currentPrice, extraPrice)}
      onAddedToCart={closePersonalizeModal}
      disabled={productVariants.every((v) => !v.availableForSale)}
      typeIcon={true}
      showUpsells={handleShowUpsells}
      fakeCart={canFakeCart}
      fakeCartClick={handleFakeCartClick}
    />
  )

  const productWizardElem = (
    <WizardComponent id={'1'} key={1}>
      <WizardContainer hasError={hasErrors}>
        {!isMobile && (
          <ProductWizard
            setExtraPrice={setExtraPrice}
            key={11}
            template={pageType}
            options={computedProductOptions}
            onChange={handleProductChange}
            onDone={setWizardDone}
            productColors={productColors}
            currentProductColorId={productData.color}
            addToCart={addToCartDoneElem}
            productAttributes={allAttrs()}
          />
        )}
      </WizardContainer>

      {hasErrors ? <WizardError>{t('personalizeMissing')}</WizardError> : null}
    </WizardComponent>
  )
  const productWizardElemModal = (
    <WizardComponent id={'2'} key={2}>
      <WizardContainer hasError={hasErrors}>
        {isMobile && (
          <ProductWizard
            setExtraPrice={setExtraPrice}
            key={22}
            template={pageType}
            options={computedProductOptions}
            onChange={handleProductChange}
            onDone={setWizardDone}
            productColors={productColors}
            currentProductColorId={productData.color}
            addToCart={addToCartDoneElem}
            productAttributes={allAttrs()}
          />
        )}
      </WizardContainer>

      {hasErrors ? <WizardError>{t('personalizeMissing')}</WizardError> : null}
    </WizardComponent>
  )
  const addToCartElem = (
    <AddToCartButton
      onError={setHasErrors}
      mainVariant={currentMainVariant}
      attributes={allAttrs()}
      readyToAdd={allOptionsSelected}
      additionalVariants={currentAdditionalVariants}
      currentPrice={priceAdjust(currentPrice, extraPrice)}
      onAddedToCart={closePersonalizeModal}
      disabled={productVariants.every((v) => !v.availableForSale)}
      showUpsells={handleShowUpsells}
      fakeCart={canFakeCart}
      fakeCartClick={handleFakeCartClick}
    />
  )

  const sizeGuideElem = sizeGuide ? (
    <SizeGuide
      modalTitle={sizeGuide.title}
      modalContent={sizeGuide.description}
    />
  ) : null

  const klarnaElem = <KlarnaWidget price={currentPrice} />

  // OTHER

  const mappedMaterials = (() => {
    if (!productData?.materials || !productMaterials) return []

    return productData.materials.reduce<ProductMaterialProps[]>(
      (acc, productMaterial) => {
        const sanityMaterial = productMaterials.find(
          (sanityMaterial) => sanityMaterial.shopifyId === productMaterial,
        )

        return sanityMaterial
          ? [
              ...acc,
              {
                label: sanityMaterial?.label,
                description: sanityMaterial?.description,
                image: sanityMaterial?.image,
              },
            ]
          : acc
      },
      [],
    )
  })()

  const seoImage = (() => {
    if (productData.featuredImage) {
      return productData.featuredImage.image
    }
    if (productData.images.length > 0) {
      return productData.images[0].image
    }
    return undefined
  })()

  return (
    <Root>
      <PortalProductCart
        media={productData?.media as ShopifyImageMediaPayload[]}
        currentPrice={currentPrice as ShopifyPrice}
        isHidden={isHidden}
      >
        <Col>
          {currentProductColor && (
            <ProductColor>{currentProductColor.label}</ProductColor>
          )}
          <PortalProductTitle>{productData.title}</PortalProductTitle>
          {productData.reviewsSummary && (
            <ProductReviews {...productData.reviewsSummary} />
          )}
          {currentPrice && (
            <PortalPricePrimitive>
              <PricePrimitive price={currentPrice} />
            </PortalPricePrimitive>
          )}
        </Col>
        <Col>
          <AddToCartButton
            onError={setHasErrors}
            mainVariant={currentMainVariant}
            attributes={allAttrs()}
            readyToAdd={allOptionsSelected}
            additionalVariants={currentAdditionalVariants}
            currentPrice={priceAdjust(currentPrice, extraPrice)}
            onAddedToCart={closePersonalizeModal}
            disabled={productVariants.every((v) => !v.availableForSale)}
            isPortal={true}
            showUpsells={handleShowUpsells}
            fakeCart={canFakeCart}
            fakeCartClick={handleFakeCartClick}
          />
          <ContainerButton>
            <Button
              appearance={'solidRed'}
              onClick={openPersonalizeModal}
              disabled={productVariants.every((v) => !v.availableForSale)}
            >
              {productVariants.every((v) => !v.availableForSale) ? (
                t('outOfStock')
              ) : (
                <>
                  {t('addToCart')}
                  {currentPrice && (
                    <>
                      {' '}
                      —&nbsp;
                      {canFakeCart ? (
                        t('customize')
                      ) : (
                        <PricePrimitive
                          price={formatDiscount(currentPrice, discount)}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Button>
          </ContainerButton>
        </Col>
      </PortalProductCart>

      <ProductJsonLd
        productName={productData?.title}
        images={
          seoImage && [
            shopifyImageLoader({ src: seoImage.src, width: 1200, height: 630 }),
          ]
        }
        description={productData?.description}
        offers={[
          {
            price: productData.priceRange.minVariantPrice.amount,
            priceCurrency: productData.priceRange.minVariantPrice.currencyCode,
            itemCondition: 'https://schema.org/NewCondition',
            availability: 'https://schema.org/InStock',
            url: fullUrl,
            seller: {
              name: 'Magal Jewelry',
            },
          },
        ]}
        brand="Magal Jewelry"
      />
      <GalleryArea>{galleryElem()}</GalleryArea>

      <Aside id="productCart">
        <MetaArea>
          {currentProductColor && (
            <ProductColor>{currentProductColor.label}</ProductColor>
          )}
          <ProductTitle>{productData.title}</ProductTitle>

          <ProductMetaData>
            <ProductMetaDataFull>
              <PriceWrap>
                {productData.reviewsSummary && (
                  <ReviewWrapper>
                    <ProductReviews {...productData.reviewsSummary} />
                  </ReviewWrapper>
                )}
                {isConfiguratorDirty ? (
                  <ProductVariantPrice
                    price={priceAdjust(currentPrice, extraPrice)}
                    noZeros
                    compareAtPrice={
                      currentCompareAtPrice || {
                        amount: `${currentPrice?.amount}`,
                        currencyCode: 'USD',
                      }
                    }
                  />
                ) : (
                  <ProductPrice
                    priceRange={productData.priceRange}
                    compareAtPriceRange={productData.compareAtPriceRange}
                    theme={'big'}
                  />
                )}
              </PriceWrap>
            </ProductMetaDataFull>
            <ProductTiles tiles={productTiles} />
          </ProductMetaData>
        </MetaArea>

        {(pageType === 'SINGLE_VARIANT' ||
          pageType === 'VARIANT_DERIVED_FROM_OPTIONS') && (
          <OptionsArea>
            {productColors && (
              <>
                <ProductOptionLabel>{t('chooseMaterial')}</ProductOptionLabel>
                <ProductSwatches
                  productColors={productColors}
                  currentProductColorId={productData.color}
                  preventScroll={true}
                />
              </>
            )}
            <ProductOptions
              options={computedProductOptions}
              onChange={handleProductChange}
            />
          </OptionsArea>
        )}

        {pageType === 'ADVANCED_TEMPLATE' && (
          <WizardWrap>{productWizardElem}</WizardWrap>
        )}

        {sizeGuideElem}

        {upsells?.enabled && upsells?.upsellItems && (
          <WrapperUpsells closeUpsells={showUpsellsMobile}>
            <Upsells handleShowUpsells={handleShowUpsells} />
          </WrapperUpsells>
        )}

        <ContainerElements intersect={handleIntersection}>
          <>
            <BuyArea hideOnMobile={pageType === 'ADVANCED_TEMPLATE'}>
              {klarnaElem}
              {addToCartElem}
            </BuyArea>

            {pageType === 'ADVANCED_TEMPLATE' && (
              <PersonalizeModal
                gallery={galleryElem(true)}
                klarna={klarnaElem}
                wizard={productWizardElemModal}
                addToCart={addToCartElem}
                sizeGuide={sizeGuideElem}
                onRequestClose={closePersonalizeModal}
                onRequestOpen={openPersonalizeModal}
                isOpen={personalizeModalOpen}
                disabled={productVariants.every((v) => !v.availableForSale)}
                reassuranceItems={reassurance}
              />
            )}
          </>
        </ContainerElements>

        <Reassurance items={reassurance} list />

        <DetailsArea>
          {enableTransparentPricing && (
            <AccordionTransparentPricing
              title={transparentPricingLabel}
              priceRange={productData.priceRange}
              transparentPricingMultiplier={transparentPricingMultiplier}
            />
          )}
          <AccordionProductDetails
            title={productDescriptionLabel}
            materials={enableMaterials ? mappedMaterials : null}
            descriptionHTML={productData.descriptionHtml}
          />
          {enableProductCare && (
            <AccordionWithContent
              title={productCareLabel}
              description={productCare}
            />
          )}
          {enableShippingReturns && (
            <AccordionWithContent
              title={shippingReturnsLabel}
              description={shippingReturns}
            />
          )}
          <hr />
        </DetailsArea>
      </Aside>
    </Root>
  )
}
